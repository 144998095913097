<template>
  <div class="changeMaterial">
    <headbox
      title="换料结算"
      :isbackPath="true"
      path="person"
      bgColor="#f83637"
      iswhite="true"
    ></headbox>
    <van-pull-refresh
      v-model="isLoading"
      success-text="刷新成功"
      @refresh="onRefresh"
      style="min-height: 100vh;"
    >
      <template v-if="ishow">
        <van-empty
          class="custom-image"
          :image="require('@/assets/images/pic_no information@2x.png')"
          description="暂无数据!"
        />
      </template>
      <template v-if="!ishow">
        <div>
          <!--          <div v-for="(items) in changeMaterialList" :key="items.openId" >-->
          <div
            v-for="(itemslist, index) in changeMaterialList"
            :key="index"
            @click="hrefdetails(itemslist.openId)"
          >
            <div class="flex">
              <div class="left" style="background:#FF2D2E">
                <div class="text">换料结算</div>
              </div>
              <div class="right">
                <div class="orderInfo">
                  <div class="flex_between">
                    <h1 class="comeWight">来料重量 {{ itemslist.weight }}g</h1>
                    <div class="status">
                      {{
                        itemslist.payStatus == "WAIT_CONFIRM"
                          ? "待确认"
                          : "已完成"
                      }}
                    </div>
                    <!-- <div class="status">{{text}}</div> -->
                  </div>
                </div>
                <div class="orderNmber">
                  <div>
                    <div class="number van-ellipsis">
                      订单单号: {{ itemslist.openNo }}
                    </div>
                    <div class="time">
                      时间: {{ itemslist.created | formatDate_ }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--          </div>-->
        </div>
      </template>
    </van-pull-refresh>
  </div>
</template>

<script>
import headbox from "@/components/head.vue";
import { getChangeMaterialDara } from "@/utils/api";
import * as JieSuanOrderListApi from "@/utils/api";
import { formatDate_ } from "@/utils/filters";
export default {
  data() {
    return {
      isLoading: false, // 是否下拉加载状态
      ishow: true, // 是否有数据
      changeMaterialList: [] // 换料数据
    };
  },
  components: {
    headbox
  },
  filters: {
    formatDate_
  },
  mounted() {
    this.forceLandscapeScreenHandle1();
    getChangeMaterialDara()
      .then(res => {
        console.log("res", res);
        this.changeMaterialList = res.content;
        if (this.changeMaterialList.length != 0) {
          this.ishow = false;
        } else {
          this.ishow = true;
        }
      })
      .catch(err => {
        this.ishow = true;
      });
  },
  methods: {
    // 下拉事件
    async onRefresh() {
      try {
        const res = await getChangeMaterialDara();
        console.log("res", res);
        this.changeMaterialList = res.content;
        if (this.changeMaterialList.length != 0) {
          this.ishow = false;
        } else {
          this.ishow = true;
        }
        console.log("this.changeMaterialList", this.changeMaterialList);
        this.$Loading.close();
      } catch (err) {
        this.ishow = true;
      }
      setTimeout(() => {
        this.isLoading = false;
      }, 500);
    },
    hrefdetails(orderId) {
      console.log(orderId);
      this.$router.push({
        name: "changeMaterialTable",
        query: { orderid: orderId }
      });
    },
    forceLandscapeScreenHandle1() {
      const body = document.getElementsByTagName("body")[0];
      const html = document.getElementsByTagName("html")[0];
      const width = html.clientWidth;
      const height = html.clientHeight;
      const max = width > height ? width : height;
      const min = width > height ? height : width;
      body.style.width = min + "px";
      body.style.height = max + "px";
    }
  }
};
</script>

<style lang="scss" scoped="true">
.changeMaterial {
  background-color: #f5f5f5;
}
#loadingbox {
  margin-top: 150px;
}
::v-deep {
  .van-loading__text {
    font-size: 30px;
  }
  .van-list__finished-text {
    font-size: 30px;
  }
  .van-pull-refresh__head {
    font-size: 30px !important;
  }
  .van-empty__image {
    width: 420px;
    height: 260px;
  }
}
.van-empty {
  margin-top: 150px;
}
::v-deep {
  .van-pull-refresh {
    padding: 30px 40px 40px;
  }
}
.flex_between {
  display: flex;
  justify-content: space-between;
}
.flex {
  width: 100%;
  display: flex;
  margin-bottom: 40px;
  .left {
    width: 40px;
    height: 162px;
    background-color: #cc0e0f;
    padding: 24px 0;
    border-radius: 8px 0px 0px 8px;
    .text {
      width: 24px;
      height: 120px;
      font-size: 24px;
      color: #ffffff;
      line-height: 30px;
      margin: auto;
    }
  }
  .right {
    width: 100%;
    background-color: #fff;
    height: 162px;
    padding: 0 20px;
    .orderInfo {
      padding: 10px 0;
      border-bottom: 1px solid #e9e9e9;
      .comeWight {
        color: #242424;
        font-size: 32px;
        font-weight: 500;
        line-height: 42px;
      }
      .status {
        width: 120px;
        height: 48px;
        text-align: center;
        line-height: 48px;
        background-color: #fff;
        border: 1px solid #ff2d2e;
        border-radius: 50px;
        font-size: 28px;
        color: #ff2d2e;
      }
      .BrokenWeight {
        color: #999999;
        font-size: 28px;
        line-height: 34px;
      }
    }
    .orderNmber {
      padding: 20px 0;
      color: #999999;
      font-size: 24px;
    }
  }
}
</style>
